import 'core-js/stable';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { addGTM } from './utils';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // Add GTM (can't do while prerendering)
  addGTM();
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement,
  );
} else {
  // Pre-render, or dev
  if (process.env.NODE_ENV === 'development') {
    addGTM(); // add tracking here for QA
  }
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
